@mixin tag-pill() {
  font-size: .6rem;
  padding: 0.35rem 0.5rem;
  display: inline-block;
  height: 1.3rem;
  white-space: nowrap;
  margin: .15rem;
}

main {
  margin-top: 3rem;
}

nav {
  label.burger {
    float: left;
  }
}

#tagsList {
  label {
    :checked + span.toggle, :checked + span.toggle:hover {
      //inset 0 0 0 99em rgba(17, 17, 17, 0.2); // original
      box-shadow: inset 3px 3px 3px 3px red;
    }
    span {
      &.tag-pill { @include tag-pill(); }
    }
  }
}

.controls {
  div {
    button.sort {
      font-size: .7rem;
    }
    button.sort:after {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid transparent;
      content: "";
      position: relative;
      top: -10px;
      right: -4px;
    }

    button.sort.asc:after {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #2e2e2e;
      content: "";
      position: relative;
      top: 11px;
      right: -4px;
    }

    button.sort.desc:after {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid #2e2e2e;
      content: "";
      position: relative;
      top: -9px;
      right: -4px;
    }
  }
  .two-third > input {
    margin: .2rem .2rem .2rem 0rem;
  }
}

/* this is a (hopefully temporary) hack ListJS makes it really difficult to
 * alter the HTML template used for pagination.  This is a shortcut to give us
 * the styles we want for pagination.
 */
.pagination {
  padding: 0px;
  text-align: center;
  li {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    padding: 0.3em 0.9em;
    &:not(.active):not(.disabled):hover {
      text-align: center;
      vertical-align: middle;
      background: #efefef;
      border: 0;
      border-radius: 0.2em;
      width: auto;
      margin: 0.3em 0;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 0.2em;
      height: auto;
      box-shadow: 0 0 rgba(0, 0, 0, 0) inset;
    }
    &.active {
      padding: 0px;
      a.page {
        @extend .button;
      }
    }
    &.disabled:hover {
      cursor: default;
    }
  }
}

.list {
  .bookmark {
    .desc > p {
      margin: 0rem;
    }
    .uri {
      white-space: nowrap;
      overflow: auto;
    }
    .actions {
      position: relative;
      a {
        color: #2e2e2e;
        height: 1rem;
        margin: 0 .4rem;
        &:hover {
          color: grey;
        }
      }
      .action-delete {
        cursor: pointer;
        position: absolute;
        color: #e70404;
        right: 15px;
        &:hover {
          color: #d50303;
        }
      }
    }
    .tags {
      .tag-pill { @include tag-pill(); }
    }
  }
  .modal > input:checked ~ .overlay ~ * {
    overflow: visible;
  }
}

.align-center {
  text-align: center;
}

div.awesomplete {
  display: block;
  Position: relative;
}


$picnic-nav-open-left: true !default;

@import 'table';

// import iconic fonts
$iconic-font-path: "~open-iconic/font/fonts/";
@import "~open-iconic/font/css/open-iconic";

// Now import picnic and a couple of plugins
@import '~picnic/src/picnic';
//@import '../../node_modules/picnic/src/plugins/nav/plugin';
//@import '../../node_modules/picnic/plugins/modal/plugin';

@import "~awesomplete/awesomplete.base";
@import "~awesomplete/awesomplete.theme";
//@import "~awesomplete/awesomplete"

